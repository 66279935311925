import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import UserLeft from '../PersonUserLeft.vue'
import page from '../../../components/page.vue'
import NoData from '../../../components/NoData.vue'
import CheckInService from '../../../api/CheckInService.js'

export default{
	name:'UserManuscriptList',
	data(){
		return{
			pageIndex:1,
			pageSize:10,
			manuState:'',
			manuStateList:[{value:'',label:'稿件状态'},{value:'0',label:'待审核'},{value:'1',label:'已通过'},{value:'2',label:'已驳回'},{value:'3',label:'待完善'}],
			keyword:'',
			ManuscriptList:[],
			pageCount:0,
			isShowAlert:false,
			curManuId:'',//当前稿件id
		}
	},
	methods:{
		GetManuscriptList(){ //获取我的投稿
			var that = this
			CheckInService.GetManuscriptList(that.pageIndex,that.pageSize,that.manuState,that.keyword.trim(),that.$UserID).then(res=>{
				if(res.Tag == 1){
					that.ManuscriptList = res.Result
					that.pageCount = res.TotalCount
				}
			})
		},
		pageChange(page){ //切换分页
			this.pageIndex = page
			this.GetManuscriptList()
			window.scrollTo(0,0)
		},
		SearchList(){ //搜索
			this.pageIndex = 1
			this.pageCount = 0
			this.ManuscriptList = []
			this.GetManuscriptList()
		},
		DeleteManuscript(manuId){ //删除稿件提示
			var that = this
			that.isShowAlert = true
			that.curManuId = manuId
		},
		ConfirmDelete(){ //确定删除
			var that = this
			CheckInService.DeleteManuscript(that.curManuId).then(res=>{
				that.isShowAlert = false
				if(res.Tag == 1){
					that.$alert(res.Message, '提示', {
						confirmButtonText: '确定',
						callback: action => {
							if (action == "confirm") {
								that.GetManuscriptList()
							}
						}
					});
				}else{
					that.$alert(res.Message, '提示', {
						confirmButtonText: '确定'
					});
				}
			})
		}
	},
	created(){
		this.GetManuscriptList()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'UserLeft':UserLeft,
		'page':page,
		'NoData':NoData
	}
}